import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";
import { v4 as uuidv4 } from "uuid";
import { itemStockQuantity } from "../../CustomerOrder/Redux/api";

// get dyeing
export const getDyeing = createAsyncThunk(
  "dyeing/getDyeing",
  async ({ postsPerPage, startDate, endDate, customer, status, dyeingHouse, isPaid,search }, { rejectWithValue }) => {
    try {
      const { data } = await API.getDyeing({ postsPerPage, startDate, endDate, customer, status, dyeingHouse, isPaid,search });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
export const getAllDyeing = createAsyncThunk("dyeing/getAllDyeing", async (postsPerPage, { rejectWithValue }) => {
  try {
    const { data } = await API.getAllDyeing(postsPerPage);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get previous
export const getPrevious = createAsyncThunk("dyeing/getPrevious", async (previous, { rejectWithValue }) => {
  try {
    const { data } = await API.getPrevious(previous);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get next
export const getNext = createAsyncThunk("dyeing/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get particular page
export const getPageDyeing = createAsyncThunk("dyeing/getPageDyeing", async (data, { rejectWithValue }) => {
  const { number, postsPerPage } = data;
  try {
    const { data } = await API.getPageDyeing(number, postsPerPage);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

export const createDirectDyeing = createAsyncThunk("dyeing/createDirectDyeing", async (data, { rejectWithValue }) => {
  const { customerOrder, dyeingDetails, dyeingHouse, exceptedReceivedDate, remarks } = data;
  try {
    const body = JSON.stringify({
      customerOrder,
      dyeingHouse,
      dyeingDetails,
      exceptedReceivedDate,
      remarks,
    });
    const { data } = await API.createDirectDyeing(body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
export const updateDirectDyeing = createAsyncThunk("dyeing/updateDirectDyeing", async (data, { rejectWithValue }) => {
  const { id, values } = data;
  const { dyeingDetails, dyeingHouse, paymentType, exceptedReceivedDate, saveAsDraft } = values;
  try {
    const body = JSON.stringify({
      dyeingDetails,
      dyeingHouse,
      // paymentType,
      exceptedReceivedDate,
      saveAsDraft,
    });
    const { data } = await API.updateDirectDyeing(id, body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
export const dyeVerification = createAsyncThunk("dyeing/dyeVerification", async (data, { rejectWithValue }) => {
  const { id, values } = data;
  const {
    //paymentType,
    dyeingDetails,
    remarks,
  } = values;
  try {
    const body = JSON.stringify({
      //paymentType,
      dyeingDetails,
      remarks,
    });
    const { data } = await API.dyeVerification(id, body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get verified detail
export const getVefifiedDetail = createAsyncThunk("dyeing/getVefifiedDetail", async (id, { rejectWithValue }) => {
  try {
    const { data } = await API.getVefifiedDetail(id);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

export const makePayment = createAsyncThunk("dyeing/makePayment", async (values, { rejectWithValue }) => {
  const { paymentType, dyeingMaster, amount, dateOfPayment, remarks } = values;
  try {
    const body = JSON.stringify({
      paymentType,
      dyeingMaster,
      dateOfPayment,
      amount,
      remarks,
    });
    const { data } = await API.makePayment(body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// handle search
export const getSpecificDyeing = createAsyncThunk("dyeing/getSpecificDyeing", async (id, { rejectWithValue }) => {
  try {
    const { data } = await API.getSpecificDyeing(id);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// handle search
export const getSpecificRawMaterials = createAsyncThunk(
  "dyeing/getSpecificRawMaterials",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await API.getSpecificRawMaterials(id);

      if (data?.results?.length > 0) {
        const updatedDetailsPromises = data?.results?.map(async (temp) => {
          const { data: stockData } = await itemStockQuantity(temp?.item?.id, temp?.colorCode);

          if (stockData?.results?.length > 0) {
            return {
              ...temp,
              available: stockData?.results[0]?.quantity || "",
              expectedQuantity: temp.quantity,
              dyeingQuantity: temp.quantity,
              dyeingCost: 0,
              receivedQuantity: 0,
              unique: uuidv4(),
            };
          } else {
            return {
              ...temp,
              available: "",
              expectedQuantity: temp.quantity,
              dyeingQuantity: temp.quantity,
              dyeingCost: 0,
              receivedQuantity: 0,
              unique: uuidv4(),
            };
          }
        });

        const updatedDetails = await Promise.all(updatedDetailsPromises);
        return updatedDetails;
      } else {
        return data.results;
      }
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
// handle search
export const dyeingCancel = createAsyncThunk("dyeing/dyeingCancel", async (data, { rejectWithValue }) => {
  const { id, cancelReason } = data;
  try {
    const body = JSON.stringify({ status: "TERMINATED", cancelReason });
    const { data } = await API.dyeingCancel(id, body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// handle search
export const handleSearch = createAsyncThunk(
  "dyeing/handleSearch",
  async ({ postsPerPage, startDate, endDate, customer, status, search, isPaid }, { rejectWithValue }) => {
    try {
      const { data } = await API.handleSearch({ postsPerPage, startDate, endDate, customer, status, search, isPaid });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
