import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import {
  getCustomerOrder,
  getNext,
  createCustomerOrder,
  updateCustomerOrder,
  getSpecificCustomerOrder,
  getSpecificDesignComposition,
  customerOrderCancel,
  handleSearch,
} from "./thunk";

const initialState = {
  loading: false,
  loadingUpdated: false,
  loadingCustomerOrder: false,
  loadingNext: false,
  count: null,
  next: null,
  previous: null,
  customerOrders: [],
  edit: false,
  customerOrder: null,
  orderDetails: [],
  loadingSpecific: false,
  loadingCancel: false,
  loadingDesignComposition: false,
};

export const customerOrderSlice = createSlice({
  name: "customerOrder",
  initialState,
  reducers: {
    customerOrderDetailsAdded: (state, action) => {
      state.orderDetails = action.payload;
    },
    customerOrderDetailsUpdated: (state, action) => {
      state.orderDetails = action.payload;
    },
    customerOrderDetailsDeleted: (state, action) => {
      state.orderDetails = state.orderDetails.filter((detail) => detail.unique !== action.payload);
    },
    customerOrderEditSuccess: (state, action) => {
      state.edit = true;
    },
    clearOrderDetails: (state) => {
      state.orderDetails = [];
    },
    clearItemStockQuantity: (state) => {
      state.itemQuantity = "";
    },
    clearAllCustomerOrder: (state) => {
      state.edit = false;
      state.loading = false;
      state.loadingUpdated = false;
      state.loadingCustomerOrder = false;
      state.customerOrder = null;
      state.orderDetails = [];
      state.loadingItemStock = false;
      state.itemQuantity = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNext.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getNext.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.next = action.payload.next;
      state.customerOrders = [...state.customerOrders, ...action.payload.results];
    });
    builder.addCase(getNext.rejected, (state) => {
      state.loadingNext = false;
    });
    builder.addCase(createCustomerOrder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createCustomerOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(createCustomerOrder.rejected, (state) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(updateCustomerOrder.pending, (state) => {
      state.loadingUpdated = true;
    });
    builder.addCase(updateCustomerOrder.fulfilled, (state, action) => {
      state.loadingUpdated = false;
      // state.edit = false;
    });
    builder.addCase(updateCustomerOrder.rejected, (state) => {
      state.loadingUpdated = false;
      // state.edit = true;
    });
    builder.addCase(customerOrderCancel.pending, (state) => {
      state.loadingCancel = true;
    });
    builder.addCase(customerOrderCancel.fulfilled, (state, action) => {
      state.loadingCancel = false;
    });
    builder.addCase(customerOrderCancel.rejected, (state) => {
      state.loadingCancel = false;
      state.edit = false;
    });
    builder.addCase(getSpecificDesignComposition.pending, (state) => {
      state.loadingDesignComposition = true;
    });
    builder.addCase(getSpecificDesignComposition.fulfilled, (state, action) => {
      const newData = action.payload?.sort((a, b) => a.id - b.id);
      // console.log()
      state.loadingDesignComposition = false;
      state.orderDetails = newData;
    });
    builder.addCase(getSpecificDesignComposition.rejected, (state) => {
      state.loadingDesignComposition = false;
    });
    builder.addCase(getSpecificCustomerOrder.pending, (state) => {
      state.loadingSpecific = true;
    });
    builder.addCase(getSpecificCustomerOrder.fulfilled, (state, action) => {
      state.loadingSpecific = false;
      state.customerOrder = action.payload;
      state.orderDetails = action.payload.rawMaterial;
    });
    builder.addCase(getSpecificCustomerOrder.rejected, (state) => {
      state.loadingSpecific = false;
    });
    builder.addMatcher(isAnyOf(getCustomerOrder.pending, handleSearch.pending), (state) => {
      state.loadingCustomerOrder = true;
    });
    builder.addMatcher(isAnyOf(getCustomerOrder.fulfilled, handleSearch.fulfilled), (state, action) => {
      state.loadingCustomerOrder = false;
      state.customerOrders = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addMatcher(isAnyOf(getCustomerOrder.rejected, handleSearch.rejected), (state) => {
      state.loadingCustomerOrder = false;
    });
  },
});

export const {
  setAvailableDetails,
  customerOrderDetailsAdded,
  customerOrderDetailsUpdated,
  customerOrderDetailsDeleted,
  customerOrderEditSuccess,
  clearAllCustomerOrder,
  clearItemStockQuantity,
  clearOrderDetails,
} = customerOrderSlice.actions;

export default customerOrderSlice.reducer;
